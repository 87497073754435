.media-container{
  display: flex;
  flex-direction: column;
  padding-top: 5vh;

}
.action-container{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3vh;
}
.recorder-container{
  display: flex;
  justify-content: center;
}
